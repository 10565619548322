import { useState, useEffect } from 'react';
import Layout from './Layout';
import axios from 'axios';
import { Bar, Line } from 'react-chartjs-2';

import MUIDataTable from 'mui-datatables';

const Tabs = (props) => (
  <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
    <div className={`w-full ${props.color} text-white px-6 py-4`}>
      <div className="font-medium">{props.header}</div>
      <div>{props.content}</div>
    </div>
  </div>
);

export default function Dashboard() {
  const [details, setDetails] = useState({
    monthlyUsersCount: [],
    dailyUsersCount: []
  });

  const [tableColumns, setTableColumns] = useState([
    'Name',
    'Username',
    'Email',
    'Pro',
    'Shares',
    'Favourites',
    'Notes',
    'Country',
    'AppleProductHistory',
    'CreatedAt'
  ]);
  const [tableData, setTableData] = useState([]);

  const tableOptions = {
    elevation: 4,
    rowsPerPage: 100,
    rowsPerPageOptions: [100, 200, 500, 1000],
    responsive: 'standard'
  };

  const getDetails = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/dashboard`,
        {
          params: {},
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('bt')
          }
        }
      );

      setDetails(data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/users`,
        {
          params: {
            limit: 0,
            transform4Frontend: true
          },
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('bt')
          }
        }
      );

      const userDetails = data.data.users.map((userDetail) => {
        const newUserDetail = [];
        for (const tableColumn of tableColumns) {
          newUserDetail.push(userDetail[tableColumn.toLocaleLowerCase()]);
        }
        return newUserDetail;
      });

      setTableData(userDetails);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getDetails();
    getUsers();
  }, []);

  return (
    <Layout>
      <div className="px-3">
        <div className="flex flex-wrap mt-6">
          <Tabs
            header="All Users"
            content={details.allUsersCount}
            color="bg-primary"
          />
          <Tabs
            header="Pro Users"
            content={details.proUsersCount}
            color="bg-green-600"
          />
          <Tabs
            header="Free Users"
            content={details.freeUsersCount}
            color="bg-red-600"
          />
          <Tabs
            header="Shares"
            content={details.totalShares}
            color="bg-primary"
          />
          <Tabs
            header="Favourites"
            content={details.favouritesCount}
            color="bg-primary"
          />
          <Tabs
            header="Notes"
            content={details.notesCount}
            color="bg-primary"
          />
        </div>

        <MUIDataTable
          title={'All Users'}
          data={tableData}
          columns={tableColumns}
          options={tableOptions}
        />
      </div>
    </Layout>
  );
}
