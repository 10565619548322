import { useState, useEffect } from 'react';
import Layout from './Layout';
import axios from 'axios';
import { Bar, Line } from 'react-chartjs-2';

const Tabs = (props) => (
  <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
    <div className={`w-full ${props.color} text-white px-6 py-4`}>
      <div className="font-medium">{props.header}</div>
      <div>{props.content}</div>
    </div>
  </div>
);

export default function Dashboard() {
  const [details, setDetails] = useState({
    monthlyUsersCount: [],
    dailyUsersCount: []
  });

  const getDetails = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/admin/dashboard`,
        {
          params: {},
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('bt')
          }
        }
      );

      setDetails(data.data);
    } catch (error) {
      console.log('error', error);
    }
  };

  const dailyData = (data) => {
    const labels = data.map((item) => item.date).reverse();
    const noOfUsers = data.map((item) => item.noOfUsers).reverse();

    return {
      labels,
      datasets: [
        {
          label: 'Daily users count',
          data: noOfUsers,
          backgroundColor: '#38a16977',
          borderColor: '#38a169',
          borderWidth: 1
        }
      ]
    };
  };

  const monthlyData = (data) => {
    const labels = data.map((item) => item.date).reverse();
    const noOfUsers = data.map((item) => item.noOfUsers).reverse();

    return {
      labels,
      datasets: [
        {
          label: 'Monthly users count',
          data: noOfUsers,
          backgroundColor: '#1e67b9aa',
          borderColor: '#1e67b9',
          borderWidth: 1
        }
      ]
    };
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <Layout>
      <div className="px-3">
        <div className="flex flex-wrap mt-6">
          <Tabs
            header="All Users"
            content={details.allUsersCount}
            color="bg-primary"
          />
          <Tabs
            header="Pro Users"
            content={details.proUsersCount}
            color="bg-green-600"
          />
          <Tabs
            header="Free Users"
            content={details.freeUsersCount}
            color="bg-red-600"
          />
          <Tabs
            header="Shares"
            content={details.totalShares}
            color="bg-primary"
          />
          <Tabs
            header="Favourites"
            content={details.favouritesCount}
            color="bg-primary"
          />
          <Tabs
            header="Notes"
            content={details.notesCount}
            color="bg-primary"
          />
        </div>

        <div className="mt-4" style={{ height: '400px' }}>
          <Line
            data={() => dailyData(details.dailyUsersCount)}
            options={{ maintainAspectRatio: false }}
          />
        </div>

        <div className="mt-4" style={{ height: '400px' }}>
          <Bar
            data={() => monthlyData(details.monthlyUsersCount)}
            options={{ maintainAspectRatio: false }}
          />
        </div>
      </div>
    </Layout>
  );
}
