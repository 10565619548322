import { useState, useEffect } from 'react';
import Menu from './../icons/Menu';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from 'react-router-dom';

export default function Dashboard(props) {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-black bg-opacity-50 w-full lg:w-64 absolute lg:relative z-20 lg:z-0">
        <div
          className="bg-red bg-opacity-50 h-full w-full lg:w-64 absolute lg:relative transparent-bg z-0 lg:hidden"
          onClick={() => setShowMenu(false)}
        ></div>
        <div
          className={`${
            !showMenu && 'hidden lg:block'
          } w-64 bg-gray-800 text-white h-screen relative z-10`}
        >
          <div className="p-3 font-bold bg-primary mb-3">Bible Topics</div>

          <Link to="/">
            <div className="p-3">Dashboard</div>
          </Link>

          <hr className="opacity-25" />

          <Link to="/users">
            <div className="p-3">Users</div>
          </Link>
        </div>
      </div>

      {/* Body */}
      <div className={`flex-1 flex overflow-hidden`}>
        <div className="flex-1 overflow-y-scroll relative">
          {/* Navbar */}
          <div className="px-3 h-12 bg-primary flex items-center fixed w-full z-50">
            <div onClick={toggleMenu} className="block lg:hidden">
              <Menu />
            </div>
          </div>

          <div className="py-12">{props.children}</div>
        </div>
      </div>
    </div>
  );
}
