import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

export default function Login() {
  const history = useHistory();

  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const [loading, setLoading] = useState(false);

  const loginUser = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/auth/login`,
        user,
        {
          headers: {}
        }
      );
      setLoading(true);
      localStorage.setItem('bt', data.data.token);
      toast.success('Login successful');
      history.push('/');
    } catch (error) {
      setLoading(false);

      console.log('error', error.response);
      toast.error(
        error.response && error.response.data
          ? error.response.data.message
          : 'An error occured'
      );
    }
  };

  return (
    <div className="flex bg-primary justify-center items-center h-screen w-screen px-5">
      <div className="max-w-lg w-full bg-gray-300 p-8 rounded-lg">
        <div>
          <div htmlFor="email">Email</div>
          <input
            name="email"
            type="email"
            className="w-full px-2 py-2 outline-none mt-2 rounded-sm"
            onChange={(e) => {
              setUser({
                ...user,
                email: e.target.value
              });
            }}
          />
        </div>

        <div>
          <div htmlFor="password" className="mt-6">
            Password
          </div>
          <input
            name="password"
            type="password"
            className="w-full px-2 py-2 outline-none mt-2 rounded-sm"
            onChange={(e) => {
              setUser({
                ...user,
                password: e.target.value
              });
            }}
          />
        </div>

        <div className="mt-6 mb-2 justify-center flex">
          <button
            onClick={loginUser}
            className="bg-primary text-white px-4 py-2 rounded-lg outline-none focus:outline-none"
          >
            {loading ? 'Please wait...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
}
